import analytics from 'site_builder/utils/analytics.es6.js'

const ready = (callback) => {
  if (document.readyState !== 'loading') {
    callback()
  } else {
    document.addEventListener('DOMContentLoaded', callback)
  }
}

ready(() => {
  let queryString = window.location.search
  if (typeof window.B12_QUERY_STRING !== 'undefined') {
    queryString = window.B12_QUERY_STRING
  }

  const xhr = new XMLHttpRequest()
  xhr.open('GET', 'https://b12.io/client/anonymous_context/' + queryString)
  xhr.withCredentials = true
  xhr.responseType = 'json'

  xhr.onload = function () {
    if (xhr.status !== 200) {
      console.error('Failed with HTTP code ' + xhr.status)
    } else {
      const data = xhr.response
      const trackingId = data.tracking_id
      if (trackingId) {
        // TODO(marcua): merge this logic and b12-context.html
        window.b12Context = {
          trackingId,
          client: data.client,
          snowplowAppId: data.snowplow_app_id,
          client_team_member: data.client_team_member,
          experiments: data.experiments,
        }
        window.hsConversationsSettings = {
          identificationEmail: data?.primary_member?.user?.email || '',
          identificationToken: data?.hubspot_identification_token || '',
        }

        if (window.HubSpotConversations) {
          window.HubSpotConversations.widget.load()
        } else {
          window.hsConversationsOnReady = [
            () => {
              window.HubSpotConversations.widget.load()
            },
          ]
        }
        window.snowplow('newTracker', 'cf', '//prod-traffic.b12.io', {
          appId: window.b12Context.snowplowAppId,
        })
        window.snowplow('setUserId', trackingId)
        window.snowplow('trackPageView')
        analytics.trackEvent('landing', 'pageview')
      }
    }
  }

  xhr.send()
})
